import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IssueDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-remediation-change-status-form',
  templateUrl: './remediation-change-status-form.component.html',
  styleUrls: ['./remediation-change-status-form.component.scss'],
})
export class RemediationChangeStatusFormComponent extends BaseForm<any> implements OnInit {
  statusList = [
    { value: IssueDto.IssueStatusEnum.ClosedResolved, label: 'Closed Resolved' },
    { value: IssueDto.IssueStatusEnum.ClosedUnresolved, label: 'Closed Unresolved' },
  ];
  constructor(
    public viewModeService: ViewModeService,
    private fb: FormBuilder
  ) {
    super(viewModeService);
  }
  getData() {
    // return this.viewModeService.viewMode == "create"
    //     ? this.getDataKeyValueFormat()
    //     : this.getChangedFormValues().updateItems;
    return { CLOSE_ISSUES: { ...this.formGroup.getRawValue() } };
  }
  dynamicForm: FormGroup;

  setData(data: any) {
    // this.formGroup.patchValue({ ...data });
    const formGroupConfig = {};
    for (const key in data?.CLOSE_ISSUES) {
      if (data?.CLOSE_ISSUES.hasOwnProperty(key)) {
        formGroupConfig[key] = [data.CLOSE_ISSUES[key], Validators.required];
      }
    }
    this.formGroup = this.fb?.group(formGroupConfig);
    this.data = data;

    // this.formGroup?.controls?.sourceType.valueChanges.subscribe(data => {
    //     this.formGroup?.controls?.sourceCode.setValue(null)
    // })
  }
  ngOnInit(): void {}

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      CLOSE_ISSUES: new FormControl(null),
    });
  }
}
