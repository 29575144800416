<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    @for (control of formGroup.controls | keyvalue; track control) {
      <div class="col-8 flex align-items-center">
        <app-code-navigation [data]="control?.key" [showTooltip]="true"></app-code-navigation>
      </div>
      <div class="col-4 field-m-0">
        <app-drop-down-input
          [floatLabel]="false"
          [showClear]="false"
          [label]="''"
          [placeholder]="'Select Item'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="statusList"
          [optionValue]="'value'"
          [control]="control?.value"
          [viewMode]="'create'"
          [badgeView]="true"
          [appendTo]="'body'"
        ></app-drop-down-input>
      </div>
    }
  </div>

  <div class="flex flex-row-reverse gap-2">
    <!-- <app-button *ngIf="fieldViewMode != 'view' && showSaveAndClose" [action]="submitButtonAction"></app-button> -->
    @if (true) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>
