import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { RemediationPlanItemFormComponent } from './remediation-plan-item-form/remediation-plan-item-form.component';
import { RemediationPlanItemComponent } from './remediation-plan-item/remediation-plan-item.component';
import { RemediationPlanListComponent } from './remediation-plan-list/remediation-plan-list.component';
import { RemediationPlanRoutingModule } from './remediation-plan-routing.module';
import { RemediationChangeStatusFormComponent } from './remediation-change-status-form/remediation-change-status-form.component';

@NgModule({
  declarations: [
    RemediationPlanItemComponent,
    RemediationPlanItemFormComponent,
    RemediationPlanListComponent,
    RemediationChangeStatusFormComponent,
  ],
  imports: [CommonModule, RemediationPlanRoutingModule, SharedModule],
})
export class RemediationPlanModule {}
